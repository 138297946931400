import { ContractReceipt, Signer } from 'ethers';
import { CurrencyAmount, Token } from '@uniswap/sdk-core';
/* eslint-disable camelcase */
import { getIntegerString } from 'common-client';
import {
  ButtonToken__factory,
  ButtonTokenWamplRouter__factory,
  ButtonTokenWethRouter__factory,
  factories,
} from './types';
/* eslint-enable camelcase */

const interfaces = Object.values(factories).map((factory) => factory.createInterface());

function parseError(metaMaskError: any): Error {
  let sigHash: string | null = null;
  try {
    sigHash = metaMaskError.error.data.originalError.data;
  } catch (err) {
    return metaMaskError;
  }
  if (sigHash !== null) {
    for (let interfaceIndex = 0; interfaceIndex < interfaces.length; interfaceIndex += 1) {
      try {
        const error = interfaces[interfaceIndex].getError(sigHash);
        return new Error(error.name);
      } catch (err) {
        // move to next interface
      }
    }
  }
  return metaMaskError;
}

export async function wethRouterWrap(
  signer: Signer,
  depositEthAmount: CurrencyAmount<Token>,
  bWethToken: Token,
  buttonTokenWethRouterAddress: string,
): Promise<ContractReceipt> {
  const buttonTokenWethRouter = ButtonTokenWethRouter__factory.connect(
    buttonTokenWethRouterAddress, signer,
  );
  try {
    const tx = await buttonTokenWethRouter.deposit(
      bWethToken.address,
      { value: getIntegerString(depositEthAmount) },
    );
    const receipt = await tx.wait();
    return receipt;
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export async function wethRouterUnwrap(
  signer: Signer,
  bWethWithdrawAmount: CurrencyAmount<Token>,
  buttonTokenWethRouterAddress: string,
): Promise<ContractReceipt> {
  const buttonTokenWethRouter = ButtonTokenWethRouter__factory.connect(
    buttonTokenWethRouterAddress, signer,
  );
  try {
    const tx = await buttonTokenWethRouter.burn(
      bWethWithdrawAmount.currency.address,
      getIntegerString(bWethWithdrawAmount),
    );
    const receipt = await tx.wait();
    return receipt;
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export async function wamplRouterWrap(
  signer: Signer,
  depositAmplAmount: CurrencyAmount<Token>,
  bWamplToken: Token,
  buttonTokenWamplRouterAddress: string,
): Promise<ContractReceipt> {
  const buttonTokenWamplRouter = ButtonTokenWamplRouter__factory.connect(
    buttonTokenWamplRouterAddress, signer,
  );
  try {
    const tx = await buttonTokenWamplRouter.wamplWrapAndDeposit(
      bWamplToken.address,
      getIntegerString(depositAmplAmount),
    );
    const receipt = await tx.wait();
    return receipt;
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export async function wamplRouterUnwrap(
  signer: Signer,
  bWamplWithdrawAmount: CurrencyAmount<Token>,
  buttonTokenWamplRouterAddress: string,
): Promise<ContractReceipt> {
  const buttonTokenWamplRouter = ButtonTokenWamplRouter__factory.connect(
    buttonTokenWamplRouterAddress, signer,
  );
  try {
    const tx = await buttonTokenWamplRouter.wamplBurnAndUnwrap(
      bWamplWithdrawAmount.currency.address,
      getIntegerString(bWamplWithdrawAmount),
    );
    const receipt = await tx.wait();
    return receipt;
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export async function buttonTokenWrap(
  signer: Signer,
  depositUnderlyingAmount: CurrencyAmount<Token>,
  buttonToken: Token,
): Promise<ContractReceipt> {
  const buttonTokenContract = ButtonToken__factory.connect(buttonToken.address, signer);
  try {
    const tx = await buttonTokenContract.deposit(getIntegerString(depositUnderlyingAmount));
    const receipt = await tx.wait();
    return receipt;
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export async function buttonTokenUnwrap(
  signer: Signer,
  buttonWithdrawAmount: CurrencyAmount<Token>,
): Promise<ContractReceipt> {
  const buttonTokenContract = ButtonToken__factory.connect(
    buttonWithdrawAmount.currency.address, signer,
  );
  try {
    const tx = await buttonTokenContract.burn(getIntegerString(buttonWithdrawAmount));
    const receipt = await tx.wait();
    return receipt;
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}
