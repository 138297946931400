import React, { useContext } from 'react';
import { TopBarV3 } from 'common-client/dist/components/TopBarV3/TopBarV3';
import {
  Box, BoxProps, styled,
} from '@mui/material';
import {
  Chain, ConnectWalletButtonV2Wrapper, DoubleThemeContext, NetworkButtonWrapper,
} from 'common-client';
import { menuConfig } from 'config';
import { LogoWrapper } from './LogoWrapper';

const StyledWrapper = styled(Box)<BoxProps>(() => ({
  '& .MuiPaper-root': {
    background: 'transparent',
  },
}));

const routeList = [
  {
    href: 'https://button.foundation/',
    label: 'Foundation',
  },
  {
    href: 'https://app.zero.button.finance/',
    label: 'Zero',
  },
  {
    href: 'https://app.tranche.button.finance/',
    label: 'Tranche',
  },
  {
    href: 'https://app.auctions.button.finance/',
    label: 'Auctions',
  },
  {
    href: 'https://app.wrap.button.finance/',
    label: 'Wrap',
  },
];
const menuItems = menuConfig.map(({
  icon, name, url, node,
}) => ({
  label: name,
  path: url,
  image: icon,
  node,
}));

export function TopBarWrapper() {
  const { theme, toggleTheme } = useContext(DoubleThemeContext);
  return (
    <StyledWrapper>
      <TopBarV3
        logo={<LogoWrapper />}
        menuItems={menuItems}
        routeList={routeList}
        connectWalletButton={<ConnectWalletButtonV2Wrapper />}
        networkButton={
          <NetworkButtonWrapper availableChains={[Chain.Mainnet]} testChains={[Chain.Goerli]} />
        }
        onThemeSwitch={toggleTheme}
        isDarkModeEnabled={theme.palette.mode === 'dark'}
      />
    </StyledWrapper>
  );
}
