import React, {
  lazy, Suspense, useMemo,
} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntlProvider } from 'react-intl';
import {
  // TODO: add back in when we have enough routes that warrants a lazy loader
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import {
  BackgroundButtonwood,
  Ellipsis,
  ToastProvider,
  TokenListProvider,
  WalletConnectionProvider,
  Web3Provider,
  WrapperMapProvider,
} from 'common-client';
import { ConfigProvider, SUPPORTED_CHAINS } from 'config';
import { SubgraphProvider } from 'contexts/SubgraphContext';
import { TopBarWrapper } from 'wrappers/TopBarWrapper';
import { Box, BoxProps, styled } from '@mui/material';
import { withProfiler } from '@sentry/react';
import ButtonRoute from 'routes/ButtonRoute';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ethereum: any;
  }
}

window.ethereum = window.ethereum || {};

const AppDiv = styled('div', { name: 'AppDiv' })(() => ({
  minHeight: '100vh',
  WebkitTapHighlightColor: 'transparent',
}));

const BodyBox = styled(Box, { name: 'BodyBox' })<BoxProps>(() => ({
  padding: '0 20px 20px 20px',
}));

const ContentBox = styled(Box, { name: 'ContentBox' })<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    minWidth: 0,
    maxWidth: '335px',
  },
  '& > *': {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

// TODO: add back in when we have enough routes that warrants a lazy loader
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const DynamicLoader = ({ component }: { component: string }) => {
  const LazyComponent = useMemo(() => lazy(() => import(`routes/${component}`)), [component]);
  return (
    <Suspense fallback={<Ellipsis />}>
      <LazyComponent />
    </Suspense>
  );
};

const queryClient = new QueryClient();

function App() {
  return (
    <IntlProvider locale={navigator.language}>
      <Router>
        <Web3Provider>
          <WalletConnectionProvider supportedChains={SUPPORTED_CHAINS}>
            <QueryClientProvider client={queryClient}>
              <TokenListProvider>
                <WrapperMapProvider>
                  <ConfigProvider>
                    <SubgraphProvider>
                      <ToastProvider>
                        <AppDiv>
                          <BackgroundButtonwood />
                          <TopBarWrapper />
                          <BodyBox
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            flex={1}
                          >
                            <ContentBox
                              display="flex"
                              flexDirection="column"
                              alignItems="stretch"
                            >
                              <Switch>
                                <Route path="/button">
                                  <ButtonRoute />
                                </Route>
                                <Route path="/unbutton">
                                  <ButtonRoute />
                                </Route>
                                <Route path="/">
                                  <Redirect to="/button" />
                                </Route>
                              </Switch>
                            </ContentBox>
                          </BodyBox>
                        </AppDiv>
                      </ToastProvider>
                    </SubgraphProvider>
                  </ConfigProvider>
                </WrapperMapProvider>
              </TokenListProvider>
            </QueryClientProvider>
          </WalletConnectionProvider>
        </Web3Provider>
      </Router>
    </IntlProvider>
  );
}

export default withProfiler(App);
