import { Token } from '@uniswap/sdk-core';
import { ButtonAsset, NetworkConfigExtension } from 'config';
import { Asset } from 'common-client';

export function getTokenKey(
  token: Token,
  networkConfig: NetworkConfigExtension,
): Asset | ButtonAsset | null {
  const assetKey: Asset | undefined = Object.keys(networkConfig.asset)
    .map((str) => parseInt(str, 10) as Asset)
    .find((key) => networkConfig.asset[key].equals(token));

  if (assetKey !== undefined) {
    return assetKey;
  }

  const buttonAssetKey: ButtonAsset | undefined = Object.keys(networkConfig.buttonAsset)
    .map((str) => parseInt(str, 10) as ButtonAsset)
    .find((key) => networkConfig.buttonAsset[key].equals(token));

  if (buttonAssetKey !== undefined) {
    return buttonAssetKey;
  }

  return null;
}
