import { Token } from '@uniswap/sdk-core';
import { useContext, useMemo } from 'react';
import {
  AssetPairs, ButtonAsset, ConfigContext, NetworkConfigExtension, WrapDirection, Wrapper,
} from 'config';
import { Asset } from 'common-client';
import { getTokenKey } from './getTokenKey';

function getAsset(
  asset: Asset | ButtonAsset,
  wrapDirection: WrapDirection,
  networkConfig: NetworkConfigExtension,
): Token {
  if (wrapDirection === WrapDirection.wrapping) return networkConfig.asset[asset as Asset];
  return networkConfig.buttonAsset[asset as ButtonAsset];
}

export function getButtonInputs(
  wrapper: Wrapper,
  wrapDirection: WrapDirection,
  inputToken: Token | null | undefined,
  outputToken: Token | null | undefined,
): Array<Token> {
  const { networkConfig } = useContext(ConfigContext);

  return useMemo(() => {
    if (!networkConfig) {
      return [];
    }

    if (!inputToken && outputToken) {
      const pairIndex = wrapDirection === WrapDirection.unwrapping ? 0 : 1;
      const outputAssetKey: Asset | ButtonAsset | null = getTokenKey(outputToken, networkConfig);
      return AssetPairs[wrapper]
        .filter((pair) => pair[pairIndex] === outputAssetKey)
        .map(
          (pair) => (wrapDirection === WrapDirection.unwrapping
            ? networkConfig.buttonAsset[pair[1]] : networkConfig.asset[pair[0]]),
        );
    }

    const pairIndex = wrapDirection === WrapDirection.wrapping ? 0 : 1;
    return [...new Set(AssetPairs[wrapper]
      .map((pair) => pair[pairIndex]))]
      .map((asset) => getAsset(asset, wrapDirection, networkConfig));
  }, [networkConfig, wrapper, wrapDirection, inputToken, outputToken]);
}
