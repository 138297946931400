import React from 'react';
import {
  Box,
  BoxProps,
  Button, ButtonProps, Link, styled, Typography,
} from '@mui/material';
import {
  Confetti, FormatToken, FormatTokenProps,
} from 'common-client';
import { CurrencyAmount } from '@uniswap/sdk-core';
import { WrapDirection, Wrapper } from 'config';

const StyledButtonForm = styled(Box, { name: 'StyledButtonForm' })<BoxProps>(({ theme }) => ({
  width: '100%',
  maxWidth: '468px',
  margin: '0 auto',
  boxShadow: theme.shadows[4],
  border: '1px solid #D7D7D7',
  borderRadius: '32px',
  padding: '32px',
}));

const StyledButton = styled(Button, { name: 'StyledButton' })<ButtonProps>(() => ({
  borderRadius: '24px',
  color: '#fff',
  width: '100%',
  height: '48px',
  marginTop: '24px',
}));

const StyledOutputBox = styled(Box, { name: 'StyledOutputBox' })<BoxProps>(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  border: '1px solid #D7D7D7',
  borderRadius: '16px',
  padding: '15px',
  margin: '16px 0',
}));
export interface ButtonSuccessFormProps {
  /**
 * The button mode we are in
 */
wrapperMode: Wrapper;
  /**
 * The wrap mode we are in --> wrapping | unwrapping
 */
wrapDirection: WrapDirection;
handleSubmitButtonClickWrapper: () => void;
txUrl?: string | null;
exchangeBalanceTracker?: [FormatTokenProps[], FormatTokenProps[]] | null;
}

export function ButtonSuccessForm({
  wrapperMode,
  wrapDirection,
  handleSubmitButtonClickWrapper,
  txUrl,
  exchangeBalanceTracker,
}: ButtonSuccessFormProps) {
  const sent = exchangeBalanceTracker ? exchangeBalanceTracker[0] : null;
  const received = exchangeBalanceTracker ? exchangeBalanceTracker[1] : null;
  return (
    <>
      <Confetti />
      <StyledButtonForm data-testid="button-form">
        <Typography align="center" fontSize="24px" lineHeight="32px" fontWeight={900} textTransform="capitalize" mb="8px">
          { wrapperMode }
          {' '}
          Successful
        </Typography>
        <Typography align="center" fontSize="16px" lineHeight="24px" fontWeight={400} color="#757575" mb="8px">
          You have successfully wrapped your collateral into a fixed-supply asset.
        </Typography>
        {
          received && received.length && received.map((exchangeBalance: FormatTokenProps) => (
            <StyledOutputBox key={(exchangeBalance.value instanceof CurrencyAmount)
              ? exchangeBalance.value.currency.symbol : exchangeBalance.symbol}
            >
              <Typography fontSize="12px" fontWeight={700} lineHeight="12px">You Received</Typography>
              <Box />
              <FormatToken {...exchangeBalance} />
            </StyledOutputBox>
          ))
        }
        {
          sent && sent.length && sent.map((exchangeBalance: FormatTokenProps) => (
            <StyledOutputBox key={(exchangeBalance.value instanceof CurrencyAmount)
              ? exchangeBalance.value.currency.symbol : exchangeBalance.symbol}
            >
              <Typography fontSize="12px" fontWeight={700} lineHeight="12px">
                You
                {' '}
                { wrapDirection === 'wrapping' ? 'wrapped' : 'unwrapped' }
              </Typography>
              <Box />
              <FormatToken {...exchangeBalance} />
            </StyledOutputBox>
          ))
        }
        <Typography align="center" mb="16px" fontSize="16px" lineHeight="24px">
          {
            txUrl && <Link href={txUrl} target="_blank">View your transaction</Link>
          }
        </Typography>
        <StyledButton
          color="secondary"
          variant="contained"
          onClick={() => handleSubmitButtonClickWrapper()}
        >
          Done
        </StyledButton>
      </StyledButtonForm>
    </>
  );
}
