import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { WrapDirection, Wrapper } from 'config';
import { ContractReceipt } from 'ethers';
import { useCallback } from 'react';
import { useButtonWrap } from './useButtonWrap';
import { useButtonUnwrap } from './useButtonUnwrap';

export function useButtonTransform(
  wrapper: Wrapper,
  wrapDirection: WrapDirection,
): (
  inputTokenAmount: CurrencyAmount<Token>,
  outputToken: Token
) => Promise<ContractReceipt> {
  const buttonWrap = useButtonWrap(wrapper);
  const buttonUnwrap = useButtonUnwrap(wrapper);

  return useCallback((inputTokenAmount: CurrencyAmount<Token>, outputToken: Token) => (
    wrapDirection === WrapDirection.wrapping
      ? buttonWrap(inputTokenAmount, outputToken)
      : buttonUnwrap(inputTokenAmount, outputToken)),
  [wrapDirection, buttonWrap, buttonUnwrap]);
}
