import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { ConfigContext, Wrapper } from 'config';
import { ContractReceipt } from 'ethers';
import { useCallback, useContext } from 'react';
import { addressEquals, Asset, Web3Context } from 'common-client';
import { buttonTokenWrap, wamplRouterWrap, wethRouterWrap } from 'transactions';

export function useButtonWrap(
  wrapper: Wrapper,
): (
  inputTokenAmount: CurrencyAmount<Token>,
  outputToken: Token
) => Promise<ContractReceipt> {
  const { signer } = useContext(Web3Context);
  const { networkConfig } = useContext(ConfigContext);
  return useCallback((inputTokenAmount: CurrencyAmount<Token>, outputToken: Token) => {
    if (!signer) {
      return Promise.reject(new Error('Signer is undefined.'));
    }
    if (!networkConfig) {
      return Promise.reject(new Error('networkConfig is null.'));
    }
    const { asset, buttonTokenWamplRouter, buttonTokenWethRouter } = networkConfig;
    const ethToken: Token = asset[Asset.ETH];
    const amplToken: Token = asset[Asset.AMPL];

    // ETH -> bWETH
    if (addressEquals(inputTokenAmount.currency.address, ethToken.address)) {
      return wethRouterWrap(signer, inputTokenAmount, outputToken, buttonTokenWethRouter);
    }

    // AMPL -> bWAMPL
    if (addressEquals(inputTokenAmount.currency.address, amplToken.address)) {
      return wamplRouterWrap(signer, inputTokenAmount, outputToken, buttonTokenWamplRouter);
    }

    // underlying -> button
    return buttonTokenWrap(signer, inputTokenAmount, outputToken);
  }, [wrapper, signer, networkConfig]);
}
