import { Token } from '@uniswap/sdk-core';
import { useContext, useMemo } from 'react';
import {
  AssetPairs, ButtonAsset, ConfigContext, WrapDirection, Wrapper,
} from 'config';
import { Asset } from 'common-client';
import { getTokenKey } from './getTokenKey';

export function getButtonOutputs(
  wrapper: Wrapper,
  wrapDirection: WrapDirection,
  inputToken: Token | null | undefined,
): Array<Token> {
  const { networkConfig } = useContext(ConfigContext);

  const inputAssetKey: Asset | ButtonAsset | null = useMemo(() => {
    if (!networkConfig || !inputToken) {
      return null;
    }
    return getTokenKey(inputToken, networkConfig);
  }, [networkConfig, inputToken]);

  return useMemo(() => {
    if (!networkConfig || inputAssetKey === null) {
      return [];
    }
    const inputPairIndex = wrapDirection === WrapDirection.wrapping ? 0 : 1;

    return AssetPairs[wrapper]
      .filter((pair) => pair[inputPairIndex] === inputAssetKey)
      .map(
        (pair) => (wrapDirection === WrapDirection.wrapping
          ? networkConfig.buttonAsset[pair[1]] : networkConfig.asset[pair[0]]),
      );
  }, [networkConfig, inputAssetKey, wrapper, wrapDirection]);
}
