import React from 'react';
import {
  Box,
  BoxProps,
  Button, ButtonProps, styled,
} from '@mui/material';
import { ToggleSelectorV2 } from 'common-client/dist/components/ToggleSelectorV2';
import { Ellipsis, TokenInputField } from 'common-client';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { WrapDirection } from 'config';
import { ButtonFormProps } from './ButtonForm';

const StyledButtonForm = styled(Box, { name: 'StyledButtonForm' })<BoxProps>(({ theme }) => ({
  width: '100%',
  maxWidth: '468px',
  margin: '0 auto',
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  border: '1px solid #D7D7D7',
  borderRadius: '32px',
  padding: '32px',
}));

const StyledArrowButton = styled(Button, {
  name: 'StyledArrowButton',
})<ButtonProps>(() => ({
  background: 'transparent',
  margin: '0 auto',
  color: '#009B43',
  border: '1px solid #D7D7D7',
  borderRadius: '50%',
  height: '64px',
}));

const StyledButton = styled(Button, { name: 'StyledButton' })<ButtonProps>(() => ({
  borderRadius: '24px',
  width: '100%',
  height: '48px',
  marginTop: '5px',
}));

export function ButtonInputForm({
  wrapperMode,
  wrapDirection,
  step,
  handleButtonModeChange,
  handleWrapDirectionChange,
  handleSubmitButtonClickWrapper,
  inputAmount,
  setInputAmount,
  setOutputAmount,
  outputAmount,
  handleInputTokenModal,
  handleOutputTokenModal,
  userInputBalance,
  userOutputBalance,
  handleSubmitButtonApproval,
  isTokenApproved,
  tokenApprovee,
}: ButtonFormProps) {
  return (
    <StyledButtonForm data-testid="button-form">
      {
        step === 'loading' ? (
          <Ellipsis />
        ) : (
          <>
            <Box mb="24px">
              <ToggleSelectorV2
                options={['button', 'unbutton']}
                selected={wrapperMode}
                onSelect={handleButtonModeChange}
              />
            </Box>
            <TokenInputField
              label="Deposit Amount"
              amount={inputAmount}
              onUpdateAmount={(newAmount) => setInputAmount(newAmount)}
              handleTokenSelect={handleInputTokenModal}
              balance={userInputBalance}
            />
            <Box display="flex" mb="16px">
              <StyledArrowButton onClick={handleWrapDirectionChange}>
                <ArrowDownwardIcon />
              </StyledArrowButton>
            </Box>
            <TokenInputField
              label="Receive Amount"
              amount={outputAmount}
              onUpdateAmount={(newAmount) => setOutputAmount(newAmount)}
              handleTokenSelect={handleOutputTokenModal}
              balance={userOutputBalance}
            />
            {
              !isTokenApproved ? (
                <StyledButton
                  color="primary"
                  variant="contained"
                  onClick={
                  () => handleSubmitButtonApproval(tokenApprovee, inputAmount?.currency.address)
                }
                >
                  Approve
                </StyledButton>
              ) : (
                <StyledButton
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmitButtonClickWrapper()}
                >
                  {wrapDirection === WrapDirection.wrapping ? 'Wrap' : 'Unwrap'}
                </StyledButton>
              )
            }
          </>
        )
      }
    </StyledButtonForm>
  );
}
