import React, {
  Dispatch,
  SetStateAction,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { ButtonForm } from 'components/ButtonForm';
import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import {
  Asset,
  getCurrencyAmount,
  useChainTokenBalances,
  SelectTokenModalWrapper,
  useButtonConversionRateV2,
  useCheckTokenApproval,
  Web3Context,
  ETH,
  FormatTokenProps,
} from 'common-client';
import { NetworkConfigExtension, WrapDirection, Wrapper } from 'config';
import { useHistory } from 'react-router-dom';
import { getButtonInputs } from 'smartUtils/getButtonInputs';
import { getButtonOutputs } from 'smartUtils/getButtonOutputs';
import { useButtonTransform } from 'smartUtils/useButtonTransform';
import { ContractReceipt } from 'ethers';
import { getTokenApprovee } from 'smartUtils/getTokenApprovee';

export interface ButtonFormWrapperProps {
  networkConfig?: NetworkConfigExtension | null;
  /**
   * The step we are in
   */
  step: WrapperStep;
  /**
   * Callback to handle form submittal based on what WrapperStep we are in
   */
  handleSubmitButtonClick?: (
    buttonTransform: (
      inputTokenAmount: CurrencyAmount<Token>, outputToken: Token
    ) => Promise<ContractReceipt>,
    inputAmount: CurrencyAmount<Token>,
    outputToken: Token,
  ) => void;
  handleSubmitButtonApproval: (
    tokenApprovee: string | null, currencyAddress: string | undefined
  ) => void;
  txUrl?: string | null;
  setTrackedTokens?: Dispatch<SetStateAction<Token[]>>;
  exchangeBalanceTracker?: [FormatTokenProps[], FormatTokenProps[]] | null;
}

export type WrapperStep = 'start' | 'loading' | 'success' | 'error' | 'approval';

export function ButtonFormWrapper({
  networkConfig,
  step,
  handleSubmitButtonClick,
  handleSubmitButtonApproval,
  txUrl,
  setTrackedTokens,
  exchangeBalanceTracker,
}: ButtonFormWrapperProps) {
  const history = useHistory();
  const { address } = useContext(Web3Context);
  const [wrapperMode, setWrapperMode] = useState<Wrapper>(history.location.pathname.substring(1, history.location.pathname.length) || 'button');
  const [wrapDirection, setWrapDirection] = useState<WrapDirection>(WrapDirection.wrapping);
  const [inputAmount, setInputAmount] = useState<CurrencyAmount<Token> | null>(null);
  const [outputAmount, setOutputAmount] = useState<CurrencyAmount<Token> | null>(null);

  const [isInputTokenModalOpen, setIsInputTokenModalOpen] = useState<boolean>(false);
  const [isOutputTokenModalOpen, setIsOutputTokenModalOpen] = useState<boolean>(false);

  const [inputToken, setInputToken] = useState<Token | null>(null);
  const [outputToken, setOutputToken] = useState<Token | null>(null);

  const availableInputTokens = getButtonInputs(
    wrapperMode, wrapDirection, inputToken, outputToken,
  );
  const availableOutputTokens = getButtonOutputs(
    wrapperMode, wrapDirection, inputToken,
  ) || [];

  const inputChainTokenBalancesHook = useChainTokenBalances(availableInputTokens);
  // const outputChainTokenBalancesHook = useChainTokenBalances(availableOutputTokens);

  const userInputBalance = useMemo(() => {
    if (!inputChainTokenBalancesHook.output || !inputToken) {
      return null;
    }
    return inputChainTokenBalancesHook.output?.get(inputToken.address) || null;
  }, [inputChainTokenBalancesHook, inputToken]);

  useEffect(() => {
    setInputToken(outputToken);
    setOutputToken(inputToken);
    const newInputAmount = outputToken && getCurrencyAmount(0, outputToken);
    setInputAmount(newInputAmount);
    const newOutputAmount = inputToken && getCurrencyAmount(0, inputToken);
    setOutputAmount(newOutputAmount);
  }, [wrapDirection]);

  useEffect(() => {
    if (!inputToken || !outputToken || !setTrackedTokens) return;
    setTrackedTokens([inputToken, outputToken]);
  }, [inputToken, outputToken]);

  // TODO: add back in once TokenInputField is refactored
  const userOutputBalance = null;
  // const userOutputBalance = useMemo(() => {
  //   if (!outputChainTokenBalancesHook.output || !outputToken) return null;
  //   return outputChainTokenBalancesHook.output?.get(outputToken.address) || null;
  // }, [outputChainTokenBalancesHook, outputToken]);

  const buttonTransform = useButtonTransform(wrapperMode, wrapDirection);
  const tokenApprovee = getTokenApprovee(inputToken, outputToken, wrapperMode, wrapDirection);

  const approvalHook = useCheckTokenApproval(
    inputAmount || getCurrencyAmount(0, networkConfig?.asset[Asset.ETH] || ETH),
    address,
    tokenApprovee || '',
    { refetchInterval: 5000 },
  );

  const wrapperToken = useMemo(
    () => (wrapDirection === 'wrapping' ? outputToken : inputToken),
    [inputToken, outputToken],
  );

  const underlyingToken = useMemo(
    () => (wrapDirection === 'wrapping' ? inputToken : outputToken),
    [inputToken, outputToken],
  );

  const conversionHook = useButtonConversionRateV2(
    networkConfig || null,
    wrapperToken,
    underlyingToken,
  );

  const conversionHookOutput = useMemo(
    () => conversionHook.output?.[0],
    [conversionHook.output],
  );

  const handleInputAmountChange = (newAmount: CurrencyAmount<Token>) => {
    setInputAmount(newAmount);
    if (inputToken && outputToken && conversionHookOutput) {
      let convertedPrice;
      if (wrapDirection === 'wrapping') {
        convertedPrice = conversionHookOutput.invert().quote(newAmount);
      } else {
        convertedPrice = conversionHookOutput.quote(newAmount);
      }
      setOutputAmount(convertedPrice);
    }
  };

  // TODO: revist
  const handleOutputAmountChange = (newAmount: CurrencyAmount<Token>) => {
    if (wrapDirection === 'wrapping' || wrapDirection === 'unwrapping') return;
    setOutputAmount(newAmount);
    if (inputToken && outputToken && conversionHookOutput) {
      const convertedPrice = conversionHookOutput.quote(newAmount);
      setInputAmount(convertedPrice);
    }
  };

  const handleInputTokenModal = () => {
    setIsInputTokenModalOpen(true);
  };

  const handleOutputTokenModal = () => {
    setIsOutputTokenModalOpen(true);
  };

  const handleInputTokenSelect = (newToken: Token) => {
    setInputToken(newToken);
    const newInputAmount = getCurrencyAmount(0, newToken);
    setInputAmount(newInputAmount);
    setIsInputTokenModalOpen(false);
    setOutputToken(null);
    setOutputAmount(null);
  };

  const handleOutputTokenSelect = (newToken: Token) => {
    setOutputToken(newToken);
    const newOutputAmount = getCurrencyAmount(0, newToken);
    setOutputAmount(newOutputAmount);
    setIsOutputTokenModalOpen(false);
  };

  const handleWrapperModeChange = () => {
    const newWrapperMode = wrapperMode === 'button' ? Wrapper.unbutton : Wrapper.button;
    history.push(`/${newWrapperMode}`);
    setWrapperMode(newWrapperMode);
  };

  const handleWrapDirectionChange = () => {
    const newWrapDirection = wrapDirection === 'wrapping' ? WrapDirection.unwrapping : WrapDirection.wrapping;
    setWrapDirection(newWrapDirection);
  };

  const handleSubmitButtonClickWrapper = () => {
    if (!inputAmount || !outputToken || !handleSubmitButtonClick) return;
    handleSubmitButtonClick(
      () => buttonTransform(inputAmount, outputToken),
      inputAmount,
      outputToken,
    );
  };

  return (
    <>
      {
        isInputTokenModalOpen && (
          <SelectTokenModalWrapper
            open={isInputTokenModalOpen}
            quickTokenList={availableInputTokens}
            onSelect={handleInputTokenSelect}
            onClose={() => setIsInputTokenModalOpen(false)}
            tokenAllowList={availableInputTokens}
            disableImport
          />
        )
      }
      {
        isOutputTokenModalOpen && (
          <SelectTokenModalWrapper
            open={isOutputTokenModalOpen}
            quickTokenList={availableOutputTokens}
            onSelect={handleOutputTokenSelect}
            onClose={() => setIsOutputTokenModalOpen(false)}
            tokenAllowList={availableOutputTokens}
            disableImport
          />
        )
      }
      <ButtonForm
        wrapperMode={wrapperMode}
        wrapDirection={wrapDirection}
        step={step}
        handleButtonModeChange={handleWrapperModeChange}
        handleWrapDirectionChange={handleWrapDirectionChange}
        handleSubmitButtonClickWrapper={handleSubmitButtonClickWrapper}
        inputAmount={inputAmount}
        setInputAmount={handleInputAmountChange}
        outputAmount={outputAmount}
        setOutputAmount={handleOutputAmountChange}
        handleInputTokenModal={handleInputTokenModal}
        handleOutputTokenModal={handleOutputTokenModal}
        userInputBalance={userInputBalance}
        userOutputBalance={userOutputBalance}
        handleSubmitButtonApproval={handleSubmitButtonApproval}
        isTokenApproved={approvalHook.output}
        tokenApprovee={tokenApprovee}
        txUrl={txUrl}
        exchangeBalanceTracker={exchangeBalanceTracker}
      />
    </>
  );
}
