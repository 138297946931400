import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { ConfigContext, Wrapper } from 'config';
import { ContractReceipt } from 'ethers';
import { useCallback, useContext } from 'react';
import { addressEquals, Asset, Web3Context } from 'common-client';
import { buttonTokenUnwrap, wamplRouterUnwrap, wethRouterUnwrap } from 'transactions';

export function useButtonUnwrap(
  wrapper: Wrapper,
): (
  inputTokenAmount: CurrencyAmount<Token>,
  outputToken: Token
) => Promise<ContractReceipt> {
  const { signer } = useContext(Web3Context);
  const { networkConfig } = useContext(ConfigContext);
  return useCallback((inputTokenAmount: CurrencyAmount<Token>, outputToken: Token) => {
    if (!signer) {
      return Promise.reject(new Error('Signer is undefined.'));
    }
    if (!networkConfig) {
      return Promise.reject(new Error('networkConfig is null.'));
    }
    const { asset, buttonTokenWamplRouter, buttonTokenWethRouter } = networkConfig;
    const ethToken: Token = asset[Asset.ETH];
    const amplToken: Token = asset[Asset.AMPL];

    // bWETH -> ETH
    if (addressEquals(outputToken.address, ethToken.address)) {
      return wethRouterUnwrap(signer, inputTokenAmount, buttonTokenWethRouter);
    }

    // bWAMPL -> AMPL
    if (addressEquals(outputToken.address, amplToken.address)) {
      return wamplRouterUnwrap(signer, inputTokenAmount, buttonTokenWamplRouter);
    }

    // button -> underlying
    return buttonTokenUnwrap(signer, inputTokenAmount);
  }, [wrapper, signer]);
}
