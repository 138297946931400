import React from 'react';
import {
  Box,
  BoxProps,
  Button, ButtonProps, styled, Typography,
} from '@mui/material';
import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { ButtonFormWrapperProps } from 'wrappers/ButtonFormWrapper';
import { WrapDirection, Wrapper } from 'config';
import { ButtonInputForm } from './ButtonInputForm';
import { ButtonSuccessForm } from './ButtonSuccessForm';

const StyledButtonForm = styled(Box, { name: 'StyledButtonForm' })<BoxProps>(({ theme }) => ({
  width: '100%',
  maxWidth: '468px',
  margin: '0 auto',
  boxShadow: theme.shadows[4],
  border: '1px solid #D7D7D7',
  borderRadius: '32px',
  padding: '32px',
}));

const StyledButton = styled(Button, { name: 'StyledButton' })<ButtonProps>(() => ({
  borderRadius: '24px',
  color: '#fff',
  width: '100%',
  height: '48px',
  marginTop: '24px',
}));

export interface ButtonFormProps extends ButtonFormWrapperProps {
    /**
   * The button mode we are in
   */
  wrapperMode: Wrapper;
    /**
   * The wrap mode we are in --> wrapping | unwrapping
   */
  wrapDirection: WrapDirection;
  /**
   * Callback to toggle what ButtonMode we are in
   */
  handleButtonModeChange: () => void;
  /**
   * Callback to toggle what WrapDirection we are in
   */
  handleWrapDirectionChange: () => void;
  inputAmount: CurrencyAmount<Token> | null;
  setInputAmount: (newInputAmount: CurrencyAmount<Token>) => void;
  outputAmount: CurrencyAmount<Token> | null;
  setOutputAmount: (newInputAmount: CurrencyAmount<Token>) => void;
  handleInputTokenModal: () => void;
  handleOutputTokenModal: () => void;
  userInputBalance: CurrencyAmount<Token> | null;
  userOutputBalance: CurrencyAmount<Token> | null;
  handleSubmitButtonClickWrapper: () => void;
  isTokenApproved: boolean | undefined;
  tokenApprovee: string | null;
}

export function ButtonForm({
  wrapperMode,
  wrapDirection,
  step,
  handleButtonModeChange,
  handleWrapDirectionChange,
  handleSubmitButtonClickWrapper,
  inputAmount,
  setInputAmount,
  setOutputAmount,
  outputAmount,
  handleInputTokenModal,
  handleOutputTokenModal,
  userInputBalance,
  userOutputBalance,
  handleSubmitButtonApproval,
  isTokenApproved,
  tokenApprovee,
  txUrl,
  exchangeBalanceTracker,
}: ButtonFormProps) {
  if (step === 'success') {
    return (
      <ButtonSuccessForm
        wrapperMode={wrapperMode}
        wrapDirection={wrapDirection}
        handleSubmitButtonClickWrapper={handleSubmitButtonClickWrapper}
        txUrl={txUrl}
        exchangeBalanceTracker={exchangeBalanceTracker}
      />
    );
  }
  if (step === 'error') {
    return (
      <StyledButtonForm data-testid="button-form">
        <Typography align="center" fontSize="24px" lineHeight="32px" fontWeight={900} textTransform="capitalize" mb="8px">
          { wrapperMode }
          {' '}
          Unsuccessful
        </Typography>
        <Typography align="center" fontSize="16px" lineHeight="24px" fontWeight={400} color="#757575" my="32px">
          There has been an error with your transaction. Please try again.
        </Typography>
        <StyledButton
          color="primary"
          variant="contained"
          onClick={() => handleSubmitButtonClickWrapper()}
        >
          Try Again
        </StyledButton>
      </StyledButtonForm>
    );
  }

  return (
    <ButtonInputForm
      wrapperMode={wrapperMode}
      wrapDirection={wrapDirection}
      step={step}
      handleButtonModeChange={handleButtonModeChange}
      handleWrapDirectionChange={handleWrapDirectionChange}
      handleSubmitButtonClickWrapper={handleSubmitButtonClickWrapper}
      inputAmount={inputAmount}
      setInputAmount={setInputAmount}
      setOutputAmount={setOutputAmount}
      outputAmount={outputAmount}
      handleInputTokenModal={handleInputTokenModal}
      handleOutputTokenModal={handleOutputTokenModal}
      userInputBalance={userInputBalance}
      userOutputBalance={userOutputBalance}
      handleSubmitButtonApproval={handleSubmitButtonApproval}
      isTokenApproved={isTokenApproved}
      tokenApprovee={tokenApprovee}
    />
  );
}
