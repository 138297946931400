import { Token } from '@uniswap/sdk-core';
import { ConfigContext, WrapDirection, Wrapper } from 'config';
import { useContext, useMemo } from 'react';
import { addressEquals, Asset } from 'common-client';

export function getTokenApprovee(
  inputToken: Token | null,
  outputToken: Token | null,
  wrapper: Wrapper,
  wrapDirection: WrapDirection,
): string | null {
  const { networkConfig } = useContext(ConfigContext);

  return useMemo(() => {
    if (!networkConfig || !inputToken || !outputToken) {
      return null;
    }
    const { asset, buttonTokenWamplRouter, buttonTokenWethRouter } = networkConfig;
    const ethToken: Token = asset[Asset.ETH];
    const amplToken: Token = asset[Asset.AMPL];

    // Any wrap/unwrap of ETH requires eth/weth approval of buttonTokenWethRouter
    if (addressEquals(inputToken.address, ethToken.address)
      || addressEquals(outputToken.address, ethToken.address)) {
      return buttonTokenWethRouter;
    }

    // Any wrap/unwrap of AMPL requires ampl/wramp approval of buttonTokenWamplRouter
    if (addressEquals(inputToken.address, amplToken.address)
      || addressEquals(outputToken.address, amplToken.address)) {
      return buttonTokenWamplRouter;
    }

    // If wrapping, then underlying approval of wrapper is necessary
    if (wrapDirection === WrapDirection.wrapping) {
      return outputToken.address;
    }

    // If unwrapping, then no approval is necessary
    return null;
  }, [inputToken, outputToken, wrapper, wrapDirection, networkConfig]);
}
