/* eslint-disable no-console */
import React, { useContext, useState } from 'react';
import {
  Box, Link, styled, Typography, TypographyProps,
} from '@mui/material';
import {
  ButtonFormWrapper, WrapperStep,
} from 'wrappers/ButtonFormWrapper';
import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { ContractReceipt } from 'ethers';
import {
  approve,
  getTransactionUrl,
  recordTransferAmounts,
  ToastContext,
  useExchangeBalanceReducer,
  useExchangeBalanceTracker,
  Web3Context,
} from 'common-client';
import { ConfigContext } from 'config';

const StyledHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.secondary.main,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 900,
  fontSize: '40px',
  lineHeight: '48px',
  marginBottom: '8px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
}));

export default function ButtonRoute() {
  const { networkConfig } = useContext(ConfigContext);
  const { signer, address, chainId } = useContext(Web3Context);
  const { enqueueTransactionResult } = useContext(ToastContext);
  const [step, setStep] = useState<WrapperStep>('start');
  const [txUrl, setTxUrl] = useState<string | null>(null);

  const [trackedTokens, setTrackedTokens] = useState<Token[]>([]);
  const [balanceRecords, balanceRecordsDispatcher] = useExchangeBalanceReducer();
  const exchangeBalanceTracker = useExchangeBalanceTracker(balanceRecords, trackedTokens);

  const handleSubmitButtonClick = (
    buttonTransform: (
      inputTokenAmount: CurrencyAmount<Token>, outputToken: Token
    ) => Promise<ContractReceipt>,
    inputAmount: CurrencyAmount<Token>,
    outputToken: Token,
  ) => {
    if (step === 'success' || step === 'error') {
      setStep('start');
      return;
    }
    setStep('loading');
    buttonTransform(inputAmount, outputToken)
      .then((txReceipt: ContractReceipt) => {
        const transactionUrl = getTransactionUrl(
          chainId,
          txReceipt.transactionHash,
        );
        setStep('success');
        enqueueTransactionResult(
          'success',
          'Swap successful!',
          transactionUrl,
        );
        setTxUrl(transactionUrl);
        const amountsExchanged = recordTransferAmounts(txReceipt, address, null);
        balanceRecordsDispatcher(amountsExchanged);
      })
      .catch((err: Error) => {
        console.log('error', err);
        setStep('error');
        enqueueTransactionResult(
          'error',
          `Error with swapping tokens: ${err.message}`,
        );
      });
  };

  const handleSubmitButtonApproval = (
    tokenApprovee: string | null, currencyAddress: string | undefined,
  ) => {
    setStep('loading');
    if (!signer || !tokenApprovee || !currencyAddress) {
      setStep('start');
      enqueueTransactionResult(
        'error',
        'No signer, address, or approval address',
      );
      return;
    }
    approve(signer, tokenApprovee, currencyAddress)
      .then((txReceipt: ContractReceipt) => {
        setStep('start');
        const transactionUrl = getTransactionUrl(
          chainId,
          txReceipt.transactionHash,
        );
        enqueueTransactionResult(
          'success',
          `${tokenApprovee} has been approved`,
          transactionUrl,
        );
        setTxUrl(transactionUrl);
      })
      .catch((err: Error) => {
        console.log('error', err);
        setStep('error');
        enqueueTransactionResult(
          'error',
          `Error with swapping tokens: ${err.message}`,
        );
      });
  };

  return (
    <Box style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '160px',
    }}
    >
      <Box px={5}>
        <StyledHeader>
          Button Wrappers
        </StyledHeader>
        <Typography align="center" color="#757575">
          Wrap your assets to receive ButtonTokens that rebase or
          {' '}
          UnbuttonTokens that turn rebasing assets into fixed-supply assets.
        </Typography>
        <Typography align="center" color="#757575">
          You can learn more about our Button Wrappers in our
        </Typography>
        <Typography align="center" mb="40px">
          <Link
            href="https://docs.prl.one/buttonwood/developers/button-wrappers/buttontoken"
            target="_blank"
          >
            documentation
          </Link>
        </Typography>
      </Box>
      <ButtonFormWrapper
        networkConfig={networkConfig}
        step={step}
        handleSubmitButtonClick={handleSubmitButtonClick}
        handleSubmitButtonApproval={handleSubmitButtonApproval}
        txUrl={txUrl}
        setTrackedTokens={setTrackedTokens}
        exchangeBalanceTracker={exchangeBalanceTracker}
      />
    </Box>
  );
}
