import React, { ReactElement } from 'react';
import { Token } from '@uniswap/sdk-core';
import {
  addressEquals,
  Asset,
  Chain,
  createConfigs,
  NetworkConfig,
  SubgraphConfig,
  UsersnapButtonWrapper,
} from 'common-client';
import ethereumLogoUrl from 'common-client/dist/assets/images/ethereum-logo.png';
import ArticleIcon from '@mui/icons-material/Article';
import BalanceIcon from '@mui/icons-material/Balance';
// import InsertChartIcon from '@mui/icons-material/InsertChart';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PublicIcon from '@mui/icons-material/Public';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ReactComponent as DiscordIcon } from 'assets/icons/DiscordIcon.svg';
import { createSvgIcon } from '@mui/material';
import tokenlist from 'buttonwood-token-list/buttonwood.tokenlist.json';

export const SUPPORTED_CHAINS = [Chain.Mainnet, Chain.Goerli];

export const USERSNAP_GLOBAL_ID = '85158a56-8cfb-4f42-abe2-d5d25c70000c';
export const USERSNAP_PROJECT_ID = 'c667eaf5-5708-469c-83e5-f2974d12bba0';

// DO NOT EXPORT
function getToken(chainId: number, address: string): Token {
  const {
    name,
    symbol,
    decimals,
  } = tokenlist.tokens.find(
    (token) => token.chainId === chainId && addressEquals(token.address, address),
  )!;
  return new Token(chainId, address, decimals, symbol, name);
}

export enum ButtonAsset {
  bWETH,
  bWBTC,
  bWAMPL,
}

export enum Wrapper {
  button='button',
  unbutton='unbutton',
}

export const enum WrapDirection {
  wrapping='wrapping',
  unwrapping='unwrapping'
}

export const AssetPairs: { [key in Wrapper]: [Asset, ButtonAsset][] } = {
  [Wrapper.button]: [
    [Asset.ETH, ButtonAsset.bWETH],
    [Asset.WETH, ButtonAsset.bWETH],
    [Asset.WBTC, ButtonAsset.bWBTC],
    [Asset.AMPL, ButtonAsset.bWAMPL],
    [Asset.WAMPL, ButtonAsset.bWAMPL],
  ],
  [Wrapper.unbutton]: [],
};

export interface NetworkConfigExtension extends NetworkConfig {
  buttonAsset: { [key in ButtonAsset]: Token };
  buttonTokenWamplRouter: string,
  buttonTokenWethRouter: string,
}

export type ConfigExtension = Partial<Record<Chain, NetworkConfigExtension>>;

const config: ConfigExtension = {
  [Chain.Mainnet]: {
    network: {
      name: 'mainnet',
      chainId: 1,
      networkId: 1,
    },
    asset: {
      [Asset.USDT]: getToken(1, '0xdac17f958d2ee523a2206206994597c13d831ec7'),
      [Asset.AMPL]: getToken(1, '0xd46ba6d942050d489dbd938a2c909a5d5039a161'),
      [Asset.ETH]: getToken(1, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'),
      [Asset.WETH]: getToken(1, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'),
      [Asset.WAMPL]: getToken(1, '0xEDB171C18cE90B633DB442f2A6F72874093b49Ef'),
      [Asset.WBTC]: getToken(1, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'),
    },
    buttonAsset: {
      [ButtonAsset.bWETH]: getToken(1, '0x8F471E1896D16481678db553F86283EAB1561B02'),
      [ButtonAsset.bWBTC]: getToken(1, '0x8e8212386d580D8dD731a2B1a36A43935250304e'),
      [ButtonAsset.bWAMPL]: getToken(1, '0x6484F9c02fe1a0ed1edE923BE36243d361A480F5'),
    },
    chainInfo: {
      explorer: 'https://etherscan.io/',
      label: 'Ethereum',
      logoUrl: ethereumLogoUrl,
    },
    buttonTokenWamplRouter: '0x2c76A2Ce5f285f10C5674BEE172b420B4e20A983',
    buttonTokenWethRouter: '0xB08c5e2E8E73d0FB9842171496B2da04E8E80A0D',
  },
  [Chain.Goerli]: {
    network: {
      name: 'goerli',
      chainId: 5,
      networkId: 5,
    },
    asset: {
      [Asset.USDT]: getToken(5, '0xaFF4481D10270F50f203E0763e2597776068CBc5'),
      [Asset.AMPL]: getToken(5, '0x08c5b39F000705ebeC8427C1d64D6262392944EE'),
      [Asset.ETH]: getToken(5, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'),
      [Asset.WETH]: getToken(5, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6'),
      [Asset.WAMPL]: getToken(5, '0x3b624861a14979537DE1B88F9565F41a7fc45FBf'),
      [Asset.WBTC]: getToken(5, '0xF19162950528A40a27d922f52413d26f71B25926'),
    },
    buttonAsset: {
      [ButtonAsset.bWETH]: getToken(5, '0x73CB4fDe4ad1e5944a54785873b003E6f89eDBB9'),
      [ButtonAsset.bWBTC]: getToken(5, '0x37E4496AfD20e04Eec7A0Ac17410C0684a252287'),
      [ButtonAsset.bWAMPL]: getToken(5, '0x7176c51918A279641a06A4889d70670C10B2Aad9'),
    },
    chainInfo: {
      explorer: 'https://goerli.etherscan.io/',
      label: 'Goerli',
      logoUrl: ethereumLogoUrl,
    },
    buttonTokenWamplRouter: '0x22f782Bb675B7a901A71371Aab23099C887d7753',
    buttonTokenWethRouter: '0x79e645ce107700AB74b909592668C3F0F1bDC703',
  },
};

// TODO: needs to be changed to get subgraph working
export const SUBGRAPH_CONFIG: SubgraphConfig = {
  [Chain.Mainnet.valueOf()]: {
    wrapper: '',
  },
  [Chain.Goerli.valueOf()]: {
    wrapper: '',
  },
};

interface MenuLines {
  icon: ReactElement;
  name: string;
  url?: string;
  node?: ReactElement;
}

const GeneratedDiscordIcon = createSvgIcon(<DiscordIcon />, 'DiscordIcon');

export const menuConfig: Array<MenuLines> = [
  {
    icon: <TwitterIcon />,
    name: 'Twitter',
    url: 'https://twitter.com/ButtonDeFi',
  },
  {
    icon: <GeneratedDiscordIcon />,
    name: 'Discord',
    url: 'https://discord.gg/P8gFKUYtfG',
  },
  {
    icon: <PublicIcon />,
    name: 'Foundation',
    url: 'https://button.foundation/',
  },
  {
    icon: <ArticleIcon />,
    name: 'Docs',
    url: 'https://docs.prl.one',
  },
  // TODO: change to correct dune analytics page
  // {
  //   icon: <InsertChartIcon />,
  //   name: 'Analytics',
  //   url: 'https://dune.xyz/prl/<placeholder-client>',
  // },
  {
    icon: <HelpCenterIcon />,
    name: 'About',
    url: 'https://wrap.button.finance',
  },
  {
    icon: <BalanceIcon />,
    name: 'Terms',
    url: '/',
  },
  {
    icon: <FeedbackIcon />,
    name: 'Feedback',
    node: <UsersnapButtonWrapper projectId={USERSNAP_PROJECT_ID} globalId={USERSNAP_GLOBAL_ID} />,
  },
];

export function getConfig(chain: Chain): NetworkConfigExtension | null {
  if (chain === Chain.Unsupported) {
    return null;
  }
  return config[chain] || null;
}

const { ConfigContext, ConfigProvider } = createConfigs(getConfig);
export { ConfigContext, ConfigProvider };
